import ApiService from "@/core/services/api.service";
import Excel from "@/assets/js/pages/vegetable/excel.js";

var CustomerReport = {
    list: function(searchData) {
        return ApiService.post("/customer-report/list", searchData);
    },
    exportExcel: function(params) {
        Excel.exportExcel(
            "/customer-report/downLoadExcel", 
            params, 
            "CustomerReport.xls");
    }
}

export default CustomerReport;